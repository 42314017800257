.ADCcontainer {
  max-width: 800px;
  margin: 0 auto;
}

.ADCcard {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ADCcard-body {
  padding: 20px;
}

.ADCform-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.ADCform-select,
.ADCform-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ADCform-select:focus,
.ADCform-control:focus {
  border-color: #aaa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ADCbtn-outline-success {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.ADCbtn-outline-success:hover {
  background-color: #3e8e41;
  color: #fff;
}

.categories-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-select,
.edit-input,
.file-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.category-box,
.subcategory-box {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.category-name,
.subcategory-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Set fixed dimensions for all images */
.category-image,
.subcategory-image,
.preview-image {
  width: 200px; /* Fixed width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensures the image covers the space without distortion */
  border-radius: 4px;
  margin-bottom: 10px;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.edit-button {
  background-color: #007bff;
  color: #fff;
}

.save-button {
  background-color: #28a745;
  color: #fff;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.edit-button:hover,
.save-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}
.delete-button {
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #dc3545;
  color: #fff;
}

.delete-button:hover {
  opacity: 0.9;
}
