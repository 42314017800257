.admin-navbar {
  background-color: #fff;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.admin-navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-right: 20px;
}

.admin-navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.logo {
  border-radius: 50%;
  margin-left: 1rem;
  height: 3rem;
  width: 3rem;
}
.admin-navbar-item {
  margin-right: 20px;
  display: inline-block;
}

.admin-navbar-item a {
  text-decoration: none;
  color: #333;
  transition: color 0.2s ease;
}

.admin-navbar-item a:hover {
  color: #666;
}
