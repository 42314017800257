.background {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-image: url("../image/15185804_5564526.jpg"); */
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.login-card {
  width: 350px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-header h2 {
  font-weight: bold;
  margin-left: 10px;
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group svg {
  margin-right: 10px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
}

.loginButton[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
}

.loginButton[type="submit"]:hover {
  background-color: #3e8e41;
}
