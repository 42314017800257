.chart {
  justify-self: center;
  /* align-items: center; */
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.background {
  background-color: #faf9f6;
}
.outerdiv {
  padding: 2rem 4rem 2rem 4rem;
}
.outerChart {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10rem;
  width: 100%;
  max-height: 60%;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;

  padding-bottom: 2rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.reportHeading {
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 2px solid black;
}

.cardReport-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.cardReport {
  height: 23rem;
  width: calc(50% - 20px);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

/* .cardReport:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
} */

.cardReport h2 {
  font-weight: bold;
  margin-bottom: 10px;
}

.cardReport p {
  font-size: 16px;
  color: #666;
}

.smallImg {
  height: 3rem;
  width: 3.25rem;
}
h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: bold;
}
h4 {
  font-family: "arial";
}
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
