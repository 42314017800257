.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.heading {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 20px;
}

.homeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homeCard {
  margin: 20px;
  width: calc(25% - 40px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.homeCard:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.homeCard-img-top {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.homeCard-body {
  padding: 20px;
}

.homeCard-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.homeCard-text {
  font-size: 14px;
  color: #666;
}

.btn-success {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #3e8e41;
  border: none;
  color: #fff;
  cursor: pointer;
}

.btn-success:hover {
  background-color: #2f6d31;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.heading {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 10px;
}

.homeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homeCard {
  margin: 20px;
  width: calc(25% - 40px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.homeCard:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.homeCard-img-top {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.homeCard-body {
  padding: 20px;
}

.homeCard-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.homeCard-text {
  font-size: 14px;
  color: #666;
}

.btn-success {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #3e8e41;
  border: none;
  color: #fff;
  cursor: pointer;
}

.btn-success:hover {
  background-color: #2f6d31;
}

/* Make 4 homeCards per row */
@media (min-width: 992px) {
  .homeCard {
    width: calc(25% - 40px);
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .homeCard {
    width: calc(33.33% - 40px);
  }
}

@media (max-width: 767px) {
  .homeCard {
    width: calc(50% - 40px);
  }
}

@media (max-width: 575px) {
  .homeCard {
    width: 100%;
  }
}
