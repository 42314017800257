.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

.editclicked {
  width: 64%;
  margin-left: 18%;
  margin-right: 18%;
  transition: all 0.5s ease-in-out;
}

.list:hover {
  width: 64%;
  margin-left: 18%;
  margin-right: 18%;
  transition: all 0.5s ease-in-out;
}
.disabled {
  pointer-events: none;
}

.heading2 {
  margin-left: 15%;
}
.heading3 {
  display: flex;
  margin-left: 15%;
  margin-right: 15%;
  border-bottom: 1px solid black;
  justify-content: space-between;
  align-items: center;
}
.delRider {
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2%;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3px;
  animation: slide-in 0.5s;
}
.editrider {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5%;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3px;
  animation: slide-in 0.5s;
}
@keyframes slide-in {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.editbutton {
  width: 5rem;
  margin-right: 1rem;
}

.savebtn {
  justify-self: end;
  width: 25%;
}
.form1 {
  margin: 3% 10% 5% 10%;
  padding: 3% 3% 3% 3%;
  border: 1px solid black;
  border-radius: 5px;
  animation: slide-in 0.8s;
}
@keyframes slide-in {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.heading2 {
  margin-left: 10%;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.spacebtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  display: flex;
  align-self: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #333;
  border-top-color: #666;
  animation: spin 1s linear infinite;
}

.loader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #666;
  animation: pulse 1s linear infinite;
}

.loader-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.img-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ccc;

  margin-top: 20px;
}

.text-container {
  flex: 1;
  padding-right: 20px;
}

.products-image-container {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
}

.Fetch-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
